import React from 'react';
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap';

function Aboutpage() {
  return (
    <div className='aboutpagebackground'>
      <Container>
        <Row className='textbackground'>
          <Col md={7}>
            <h3 className='aboutmetext'>
              About <span>Me</span>
            </h3>
            <p className='aboutdetails'>
              I'm a qualified C# backend developer from Germany. As a software
              developer, my primary focus is on developing efficient and
              reliable backend systems. I specialize in building robust and
              liable server-side systems.
            </p>
            <ul className='skilllist'>
              <Row>
                <h3>Skills</h3>
                <Col md={7}>
                  <li>C#/C++</li>
                  <li>SQL</li>
                  <li>Kotlin</li>
                  <li>Springboot</li>
                </Col>
                <Col md={5}>
                  <li>React Js</li>
                  <li>JavaScript</li>
                  <li>TypeScript</li>
                  <li>Git/Github</li>
                </Col>
              </Row>
            </ul>
          </Col>
          <Col md={5}>
            <div className='webimage'></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Aboutpage;
