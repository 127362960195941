import React from 'react';
import '../../pages/style.css';
import { Container, Row, Col } from 'react-bootstrap';
import Text from '../Homepage/Text';
import { AiFillGithub, AiFillInstagram } from 'react-icons/ai';
import { FaLinkedinIn, FaXing } from 'react-icons/fa';

function Home() {
  return (
    <div className='homepagebackground'>
      <Container>
        <Row>
          <Col md={7}>
            <h2 className='headtext'>
              Hello <span className='wave'>👋 </span>
            </h2>
            <h2 className='nametext'>I'm Florian Böse</h2>
            <span></span>
            <Text />
            <button
              onClick={() => {
                window.open('https://github.com/Flaudiepie');
              }}
              className='socailmediabtn'
            >
              <AiFillGithub className='icon' />
            </button>
            <button
              onClick={() => {
                window.open(
                  'https://www.linkedin.com/in/florian-b%C3%B6se-3271811b5/'
                );
              }}
              className='socailmediabtn'
            >
              <FaLinkedinIn className='icon' />
            </button>
            <button
              onClick={() => {
                window.open('https://www.xing.com/profile/Florian_Boese16/cv');
              }}
              className='socailmediabtn'
            >
              <FaXing className='icon' />
            </button>
            <button
              onClick={() => {
                window.open('https://instagram.com/flaudiepie');
              }}
              className='socailmediabtn'
            >
              <AiFillInstagram className='icon' />
            </button>
          </Col>

          <Col md={5}>
            <div className='imagedeveloper'></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
